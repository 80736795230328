html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

a:link { 
  text-decoration: none; 
} 
a:visited { 
  text-decoration: none; 
} 
a:hover { 
  text-decoration: none; 
} 
a:active { 
  text-decoration: none; 
}


.topMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  color: white;
  background-color: black;
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  font-weight: bold;
}

.topMenu h5{
  font-size: 4.0rem;
  margin-bottom: 0rem;
}

@media only screen and (max-width: 600px) {
  .topMenu h5{
    font-size: 3.0rem;
    margin-bottom: 0rem;
  }
}

.pageHeader{
  padding: 30px;
  margin-top: 50px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.modal div {
  justify-content: center;
  align-items: center;
}
 
.modal button {
  margin: 5px;
  padding: 8px 20px;
  border: none;
  cursor: pointer;
}


